/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:369f21ed-aa83-471e-9a16-9e411f575e11",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_AErfn2n4x",
    "aws_user_pools_web_client_id": "64huk89gi3rvnkhvt3nqf06qfl",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://635o3abucjau3aioi3w7uujm6q.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-rz7c3rggbbd5hpp45lmkpq2tii",
    "aws_cloud_logic_custom": [
        {
            "name": "restapi",
            "endpoint": "https://a0a5q0o87l.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        },
        {
            "name": "AdminQueries",
            "endpoint": "https://kplqs7bbjg.execute-api.us-east-2.amazonaws.com/dev",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "cap22ef0744d04d74b61a195a6f4121b28a6115423-dev",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
